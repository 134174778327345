import React, { useEffect, useMemo } from 'react'
import loadable from '@loadable/component'
import {
  useLocation,
  Anchor,
  breakpoints,
  getStoredDistributionCenter,
  useShoppingCart,
  useAuth,
} from '@ecommerce/shared'
import { PageProps, navigate } from 'gatsby'
import styled, { DefaultTheme, ThemeProvider } from 'styled-components'
import { Moment, PgPageProps } from '../../types/PgPages'
import Layout from '../../components/Layout'
import Hero from '../../components/Moments/Hero'
import { contentfulThemeToThemeExtension } from '../../graphql/global/theme'
import { useCartStockErrorHandler } from '../../utils/errors'
import { BlockType } from '../../graphql/contentfulTypes'
import { backgroundImageStyles } from '../../utils/styles'
import { Icon } from '../../components/Icon/Icon'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import { extractBlocksSkus } from '../utils'
import { sendSelectItemEvent, sendPageViewEvent } from '../../utils/events'
import { generateProductUrl } from '../../config/siteBuild/utils'

const ProductSlidesList = loadable(() => import('../../components/Moments/ProductSlidesList'))
const PromosGrid = loadable(() => import('../../components/Moments/PromosGrid'))
const FilteredProductList = loadable(() => import('../../components/Moments/FilteredProductList'), { ssr: false })
const ProductsList = loadable(() => import('../home/SkuList'), { ssr: false })
const RecipeList = loadable(() => import('../../components/Recipes/RecipesList'))
const NewsList = loadable(() => import('../../components/News/NewsList'))
const VideosList = loadable(() => import('../../components/Moments/VideosList'))
const BrandsList = loadable(() => import('../../components/Moments/BrandsList'))
const HowItWorks = loadable(() => import('../../components/Moments/HowItWorks'))

type Props = PageProps<{}, { data: Moment } & PgPageProps['pageContext']>

type AnchorWrapperProps = {
  hasAnchor: boolean
}

const TemplateWrapper = styled.div<{ backgroundImage?: string }>`
  background: ${({ theme }) => theme.colors.background.body};
  color: ${({ theme }) => theme.colors.bodyText};
  ${backgroundImageStyles()}

  .MomentProductList {
    padding-bottom: 30px;
  }
`

const AnchorWrapper = styled.div<AnchorWrapperProps>`
  max-width: 1500px;
  margin: auto;
  display: none;
  padding: 20px 65px;
  .go-back {
    display: flex;
  }
  @media (${breakpoints.desktop.min}) {
    display: block;

    ${({ hasAnchor }) => !hasAnchor && `padding: 39px 65px;`}
  }
`

const MomentTemplate = ({ pageContext }: Props) => {
  const {
    data: { title, slug, description, keywords = [], blocks, theme: pageTheme, navbar },
    productCategories,
    currentDistributionCenter,
  } = pageContext

  const { isBolivia } = useLocation()

  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter

  const themeExtension = pageTheme ? contentfulThemeToThemeExtension(pageTheme) : {}
  const theme = (currentTheme: DefaultTheme) => ({
    ...currentTheme,
    colors: { ...currentTheme.colors, ...themeExtension },
  })

  const { getConnectifCart } = useShoppingCart()
  const { getEntityInfo } = useAuth()

  useEffect(() => {
    const entityInfo = getEntityInfo()
    const cart = getConnectifCart()
    sendPageViewEvent({ cart, entityInfo, event: 'momentsView', title: 'Momentos' })
  }, [])

  const stockErrorHandler = useCartStockErrorHandler()
  const themeColor = pageTheme?.brandDefault

  const skus = useMemo(() => extractBlocksSkus(blocks), [])
  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
    navigationState: {
      themeId: pageTheme?.contentful_id,
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout
        description={description}
        keywords={keywords}
        title={title}
        navbarType={navbar?.type}
        navbarTitlte={navbar?.title}
        categories={productCategories}
      >
        <TemplateWrapper backgroundImage={pageTheme?.bodyBackgroundImage?.file?.url}>
          {pageContext?.data?.navbar && (
            <AnchorWrapper hasAnchor={!!pageContext?.data?.navbar}>
              <Anchor onClick={() => navigate(`/`)} className="go-back">
                <Icon iconId="arrow_left" size="15" />
                <span>Volver al inicio</span>
              </Anchor>
            </AnchorWrapper>
          )}
          {blocks?.map((block) => {
            switch (block.blockType) {
              case BlockType.HERO: {
                return <Hero key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.PRODUCT_SLIDE: {
                return (
                  <ProductSlidesList
                    key={block.contentful_id}
                    themeId={pageTheme?.contentful_id}
                    listId={block.contentful_id}
                  />
                )
              }

              case BlockType.PROMO_XL_GRID: {
                return <PromosGrid {...block} key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.FILTERED_LIST: {
                return (
                  <FilteredProductList
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    themeId={pageTheme?.contentful_id}
                    onCardClick={({ product, listName, themeId }) => {
                      sendSelectItemEvent(product, listName, isBolivia())
                      navigate(`/products/${generateProductUrl(product.title, product.skuCode)}`, {
                        state: { themeId, originListName: listName },
                      })
                    }}
                  />
                )
              }

              case BlockType.RECIPE: {
                return (
                  <RecipeList
                    key={block.contentful_id}
                    listId={block.contentful_id}
                    actionSlug={block.actionSlug ? `/momento/${block.actionSlug}` : undefined}
                  />
                )
              }

              case BlockType.BRANDS_LIST: {
                return <BrandsList key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.PRODUCT: {
                return (
                  <ProductsList
                    pageProducts={products}
                    loadingProducts={loadingProducts}
                    key={block.contentful_id}
                    styleProps={{ bgTop: 'transparent' }}
                    stockErrorHandler={stockErrorHandler}
                    listId={block.contentful_id}
                    className="MomentProductList"
                    useBackground
                    type="large"
                  />
                )
              }

              case BlockType.VIDEO: {
                return <VideosList key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.NEWS: {
                return <NewsList key={block.contentful_id} listId={block.contentful_id} />
              }

              case BlockType.HOW_IT_WORKS: {
                return <HowItWorks key={block.contentful_id} listId={block.contentful_id} />
              }

              default:
                return null
            }
          })}
        </TemplateWrapper>
      </Layout>
    </ThemeProvider>
  )
}

export default MomentTemplate
